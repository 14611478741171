import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import slide1 from './../images/homepage/slide1.png'
import slide2 from './../images/homepage/slide2.jpg'
import slide3 from './../images/homepage/slide3.jpg'
import slide4 from './../images/homepage/slide4.png'
import slide5 from './../images/homepage/slide5.png'
import slide6 from './../images/2024/musanze-training/2.jpg'
import slide7 from './../images/homepage/slide7.png'
import slide8 from './../images/homepage/slide8.jpg'
import slide9 from './../images/homepage/slide9.png'
import slide0 from './../images/homepage/slide0.png'
import slide11 from './../images/homepage/slide11.png'
import slide12 from './../images/homepage/slide12.png'
import slide13 from './../images/homepage/slide13.jpg'
import slide14 from './../images/2024/1.jpg'

const items = [
  {
    src: slide1,
    altText: '',
    caption: 'Youth involvement in SRH services provision at myc'
  },
  {
    src: slide2,
    altText: '',
    caption: 'Young poeple - vounteers trainings'
  },
  {
    src: slide3,
    altText: '',
    caption: 'Special time for adolescents girls and young women meetings at musanze youth center'
  },
  {
    src: slide4,
    altText: '',
    caption: 'Reaching out to women leaving in rural areas with modern LARCFP methods'
  },
  {
    src: slide5,
    altText: '',
    caption: 'Recreations activities at MYC'
  },
  {
    src: slide6,
    altText: '',
    caption: 'Musanze Trainings'
  },
  {
    src: slide7,
    altText: '',
    caption: 'Community involvment CHWs orientation, meetings'
  },
  {
    src: slide8,
    altText: '',
    caption: 'Community out reach at Murandi sector- musanze district '
  },
  {
    src: slide9,
    altText: '',
    caption: 'Community outreach in SRHR services at Nyange sector '
  },
  {
    src: slide0,
    altText: '',
    caption: 'Distribution of Condoms during community out reach Nyange sector'
  },
  {
    src: slide11,
    altText: '',
    caption: 'Provision of SRH Services at Musanze youth center'
  },
  {
    src: slide12,
    altText: '',
    caption: 'SRH Education at Musanze youth center'
  },
  {
    src: slide13,
    altText: '',
    caption: 'SRH Education at Musanze youth center'
  },
  {
    src: slide14,
    altText: '',
    caption: 'Purchase ICT equipments for Health Centers'
  }
];

const Example = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="object-fill h-[48rem] w-full" />
        <CarouselCaption className="text-light" captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default Example;
